<template>
  <div>
    <el-row type="flex" justify="center">
        <el-col :span="20" :offset="2" :xs="24" :sm="24" :md="24" :lg="18" :xl="14">
          <!-- Бүх хуудсанд ашиглагдах header section -->
          <custom-header title="Дэлгэрэнгүй"/>
          <loader :active="isLoading"/>
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
                <div class="panel-item">
                  <header>Үндсэн мэдээлэл</header>
                  <div class="panel-item  border-top">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <span><strong>Урамшуулалын хэлбэр:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountTypes.find(item => item.value === discountData.type)">
                        <span>{{ discountTypes[discountTypes.findIndex(item => item.value === discountData.type)].label }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Купон код:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.discount_code }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Цацах суваг:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.channel }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Монгол нэр:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.title_mon }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Англи нэр:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.title_eng }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Монгол тайлбар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.desc_mon }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Англи тайлбар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.desc_eng }}</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <div class="panel" v-if="Object.prototype.hasOwnProperty.call(discountData, 'products')">
                <div class="panel-item">
                  <header>Нөхцөл</header>
                  <div class="panel-item border-top">
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Төлөв:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <el-tag v-if="discountData.status === 'active'" type="success" effect="dark">Active</el-tag>
                        <el-tag v-else-if="discountData.status === 'expired'" type="info" effect="dark">Expired</el-tag>
                        <el-tag v-else type="warning" effect="dark">{{discountData.status}}</el-tag>
                      </el-col>
                    </el-row>
                    <div v-if="discountData.brands.length > 0">
                      <el-row>
                       <el-col :span="8" class="mt20">
                        <span><strong>Дараахь брэндээс хэдэн ширхэгийг авбал:</strong></span>
                       </el-col>
                      </el-row>
                        <div v-for="item in discountData.brands" :key="item.brand_id">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="8">
                              {{item.brand_mon_name}}
                            </el-col>
                            <el-col :span="16">
                              <span>{{item.min_count}}ш</span>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    <div v-if="discountData.suppliers.length > 0">
                      <el-row>
                       <el-col :span="8" class="mt20">
                        <span><strong>Дараахь нийлүүлэгчээс хэдэн ширхэгийг авбал:</strong></span>
                       </el-col>
                      </el-row>
                        <div v-for="item in discountData.suppliers" :key="item.supplier_id">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="8">
                              {{item.supplier_mon_name}}
                            </el-col>
                            <el-col :span="16">
                              <span>{{item.min_count}}ш</span>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    <el-row class="mt20" v-if="discountData.categories.length > 0">
                      <el-col :span="8">
                        <span><strong>Дараахь категоруудаас хэдэн ширхэгийг авбал:</strong></span>
                    </el-col>
                    <el-col :span="16">
                      <span>{{ discountData.categories[0].min_count }}ш</span>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                        <el-tag class="ml5" v-for="item in discountData.categories" :key="item.category_id">{{item.name_mon}}</el-tag>
                    </el-row>
                      <div v-if="discountData.products.length > 0">
                        <el-row>
                        <el-col :span="8" class="mt20">
                          <span><strong>Дараахь бараануудаас хэдэн ширхэгийг авбал:</strong></span>
                        </el-col>
                        </el-row>
                        <div v-for="product in discountData.products" :key="product.variant_id">
                            <el-row :gutter="5" type="flex" align="middle" class="mt10 pt10 border-top">
                              <el-col :span="4" :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                                <div class="image-holder">
                                  <img :src="product.variant_image" alt="">
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="text-color-secondary">{{product.product_name}}</div>
                                <div>{{product.variant_name}}</div>
                                <div class="text-color-placeholder">{{product.variant_sku}}</div>
                              </el-col>
                            <el-col :span="12">
                              <span>{{product.min_count}}ш</span>
                            </el-col>
                            </el-row>
                        </div>
                      </div>
                    <el-row :gutter="20" class="mt20" v-if="discountData.min_pay_total !== null">
                      <el-col :span="8">
                        <span><strong>Худалдан авалтын дүнгийн доод хязгаар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{discountData.min_pay_total}}₮</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.max_pay_total !== null">
                      <el-col :span="8">
                        <span><strong>Худалдан авалтын дүнгийн дээд хязгаар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{discountData.max_pay_total}}₮</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.start_date !== null">
                      <el-col :span="8">
                        <span><strong>Урамшуулалын эхлэх огноо:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.start_date }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.end_date !== null">
                      <el-col :span="8">
                        <span><strong>Урамшуулалын дуусах огноо:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.end_date }}</span>
                      </el-col>
                    </el-row>
                  </div>
              </div>
              </div>
              <div class="panel" v-if="Object.prototype.hasOwnProperty.call(discountData, 'discount')">
                <div class="panel-item">
                  <header>Урамшуулал</header>
                  <div class="panel-item border-top">
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Урамшуулалын төрөл:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountSubTypes.find(item => item.value === discountData.discount_type)">
                        <span>{{ discountSubTypes[discountSubTypes.findIndex(item => item.value === discountData.discount_type)].label }}</span>
                      </el-col>
                    </el-row>
                    <div v-if="discountData.discount !== null && discountData.discount.free_products">
                      <el-row>
                       <el-col :span="8" class="mt20">
                        <span><strong>Дараахь бүтээгдэхүүнээс:</strong></span>
                       </el-col>
                      </el-row>
                        <div v-for="item in discountData.discount.free_products" :key="item.product_id">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="8">
                              {{item.product_name}}
                            </el-col>
                            <el-col :span="16">
                              <span>{{item.count}}ш</span>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    <el-row :gutter="20" class="mt20" v-if="discountData.discount !== null && discountData.discount.saled_percentage">
                      <el-col :span="8">
                        <span><strong>Хямдрах хувь:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountData.discount !== null && Object.prototype.hasOwnProperty.call(discountData.discount, 'saled_percentage')">
                        <span>{{ discountData.discount.saled_percentage }}%</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.discount !== null && discountData.discount.saled_price">
                      <el-col :span="8">
                        <span><strong>Хямдрах дүн:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountData.discount !== null && Object.prototype.hasOwnProperty.call(discountData.discount, 'saled_price')">
                        <span>{{ discountData.discount.saled_price }}₮</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.usage_limit !== null">
                      <el-col :span="8">
                        <span><strong>Хэрэглээний хязгаар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.usage_limit }} удаа</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.one_user_limit !== null">
                      <el-col :span="8">
                        <span><strong>Нэг хэрэглэгч хэдэн удаа хэрэглэх:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.one_user_limit }} удаа</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="text-right">
                <el-button type="default" @click="goBack"
                  >Буцах
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
import Loader from '../../../components/common/loader'
export default {
  name: 'detailDiscount',
  components: {
    CustomHeader,
    Loader
  },
  data () {
    return {
      treeDefaultProps: {
        children: 'childrens',
        label: 'name_mon'
      },
      isUploading: true,
      discountSubTypes: [
        {
          value: 'PERCENTAGE',
          label: 'Хувиар %'
        },
        {
          value: 'FIXED_AMOUNT',
          label: 'Үнийн дүнгээр'
        },
        {
          value: 'FREE_DELIVERY',
          label: 'Үнэгүй хүргэлт'
        },
        {
          value: 'PRODUCT',
          label: 'Бүтээгдэхүүн'
        }
      ],
      discountItemTypes: [
        {
          value: 'all_product',
          label: 'Бүх бүтээгдэхүүн'
        },
        {
          value: 'products',
          label: 'Сонгосон бүтээгдэхүүн'
        },
        {
          value: 'categories',
          label: 'Сонгосон ангилалын бүх бүтээгдэхүүн'
        },
        {
          value: 'suppliers',
          label: 'Сонгосон нийлүүлэгчийн бүх бүтээгдэхүүн'
        },
        {
          value: 'brands',
          label: 'Сонгосон брендийн бүх бүтээгдэхүүн'
        }
      ],
      discountTypes: [
        {
          value: 'DISCOUNT_CODE',
          label: 'Купон код'
        },
        {
          value: 'AUTO',
          label: 'Автоматаар бодогдох'
        }
      ],
      isLoading: false,
      discountData: []
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.getOneDiscount(this.$route.params.id)
    }
  },
  methods: {
    filterNode (value, data) {
      if (!value) return true
      return this.discountData.categories.name_mon.indexOf(value) !== -1
    },
    goBack () {
      this.$router.go(-1)
    },
    getOneDiscount (id) {
      this.isLoading = true
      services.getOneDiscount(id).then((response) => {
        if (response.status === 'success') {
          this.discountData = response.data
          this.isLoading = false
        }
      })
    }
  }
}
</script>
